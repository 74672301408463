import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiLogin, apiGenerateMenu, apiSignUp } from 'services/Auth/AuthService'
import { onSignInSuccess, onSignOutSuccess, setNavigation } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
			const resp = await apiLogin(values);
			const resBody = resp.data.data[0];
			const menu = await apiGenerateMenu(resBody.mu_username);
			const navigationConfig = menu.data.data.navigationConfig
			dispatch(setNavigation(navigationConfig))

			if (resBody) {
				const { token } = resBody
				dispatch(onSignInSuccess(token))
				if(resBody) {
					dispatch(setUser({ 
						avatar    : '',
						username  : resBody.mu_username,
						store     : resBody.mu_ms_id,
						authority : [],
						email     : '',
						canAccess : resBody.roleAccess ? resBody.roleAccess : [],
						mode      : 'light',
						goldRate  : resBody.gold_rate ? resBody.gold_rate : 0,
						usdRate  : resBody.usd_rate ? resBody.usd_rate : 0,
					}))
					
				}


				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}
		} catch (err) {
			return {
                status: 'failed',
                message: err?.response?.data?.errors[0]?.message || err.toString()
            }
		}
    }

	const signUp = async (values) => {
        try {
			const resp = await apiSignUp(values)
			if (resp.data) {
				const { token } = resp.data
				dispatch(onSignInSuccess(token))
				if(resp.data.user) {
					dispatch(setUser(resp.data.user || { 
						avatar: '', 
						userName: 'Anonymous', 
						authority: ['USER'], 
						email: ''
					}))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		// await apiSignOut()
		handleSignOut()
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
		signUp,
        signOut
    }
}

export default useAuth