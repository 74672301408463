import ApiService from '../ApiService'


export async function apiLogin(data) {
    return ApiService.fetchData({
        url: process.env.REACT_APP_AUTH_URL + '/login',
        method: 'post',
        data
    })
}
export async function apiGenerateMenu(username) {
    return ApiService.fetchData({
        url: process.env.REACT_APP_AUTH_URL + "/menu/get/" + username,
        method: "get"
      });
}

export async function apiSignUp (data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data
    })
}

export async function apiSignOut (data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword (data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}

export async function apiValidatePassword (data) {
    return ApiService.fetchData({
        url: process.env.REACT_APP_AUTH_URL + '/login/validate-password',
        method: 'post',
        data
    })
}

export async function apiChangePassword (data) {
    return ApiService.fetchData({
        url: process.env.REACT_APP_AUTH_URL + '/login/change-password',
        method: 'post',
        data
    })
}
