import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import React from "react";
import { apiGenerateMenu } from 'services/Auth/AuthService'

export const getMenu = createAsyncThunk('auth/session/getMenu',async (data) => {
    const response = await apiGenerateMenu(data)
    console.log(response)
    return response.data.data[0]
})

export const sessionSlice = createSlice({
	name: 'auth/session',
	initialState: {
        token: '',
        signedIn: false,
        navigationConfig: []
    },
	reducers: {
        onSignInSuccess: (state, action) => {
            state.signedIn = true
            state.token = action.payload
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
        },
        setToken: (state, action) =>  {
            state.token = action.payload
        },
        setNavigation: (state, action) =>  {
            state.navigationConfig = action.payload
        },
        setRoutes: (state, action) =>  {
            state.routesConfig = action.payload
        },
        
	},
})

export const { onSignInSuccess, onSignOutSuccess, setToken, setNavigation, setRoutes } = sessionSlice.actions

export default sessionSlice.reducer