import React, { useEffect, useState } from "react";
import { Card, Button } from "components/ui";
import { HiQrcode } from "react-icons/hi";
import QrScanner from "qr-scanner";

let stopScan = false;

const CheckPriceScanner = (props) => {
  const [btnScan, setBtnScan] = useState(true);

  const startScan = async (isScan) => {
    setBtnScan(isScan);
    if (isScan) stopScan = true;
    if (btnScan === false) return;
    stopScan = false;
    await new Promise((r) => setTimeout(r, 100));
    const videoElement = document.getElementById("scanView");

    const scanner = new QrScanner(
      videoElement,
      (result) => {
        props.onScan(result.data);
        setBtnScan(true);
        stopScan = true;
      },
      {
        onDecodeError: (err) => {
          console.error(err);
        },
        maxScansPerSecond: 2,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailScanResult: true,
      }
    );

    await scanner.start();
    while (stopScan === false) {
      await new Promise((r) => setTimeout(r, 100));
    }
    scanner.stop();
    scanner.destroy();
  };

  return (
    <>
      <Card className="flex items-center justify-center" style={{height: 250}}>
        {btnScan === false 
        ? (
          <video
            id="scanView"
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "100%",
              maxHeight: "400px",
              borderStyle: "dotted",
            }}
          >
          </video>
          ) 
          : (<div style={{height: 250}}/>)
          }
      </Card>
      <div className="flex justify-center mt-4">
        <Button
          onClick={() => startScan(!btnScan)}
          size="lg"
          icon={<HiQrcode />}
        >
          Open Scan
        </Button>
      </div>
    </>
  );
};

export default CheckPriceScanner;
