import React, { forwardRef, useMemo, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Pagination, Select, Checkbox } from 'components/ui'
import TableRowSkeleton from './loaders/TableRowSkeleton'
import Loading from './Loading'
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table'
import { useSelector } from 'react-redux'

const { Tr, Th, Td, THead, TBody, Sorter } = Table

const IndeterminateCheckbox = forwardRef((props, ref) => {

	const { 
		indeterminate, 
		onChange, 
		onCheckBoxChange, 
		onIndeterminateCheckBoxChange,
		...rest 
	} = props

	const defaultRef = useRef()
	const resolvedRef = ref || defaultRef

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate
	}, [resolvedRef, indeterminate])

	const handleChange = (e) => {
		onChange(e)
		onCheckBoxChange?.(e)
		onIndeterminateCheckBoxChange?.(e)
	}

	return (
		<Checkbox 
			className="mb-0" 
			ref={resolvedRef} 
			onChange={(_, e) => handleChange(e)}
			{...rest} 
		/>
	)
})

const DataTable = (props) => {

	const themeColor = useSelector((state) => state.auth.user.mode);
	const [bgColorHead, setBgColorHead] = useState("#f9fafb")
	const [bgColorBody, setBgColorBody] = useState("#fff")

	useEffect(()=>{
		if(themeColor === "dark"){
			setBgColorHead('#374151')
			setBgColorBody('#1f2937')
		}else{
			setBgColorHead('#f9fafb')
			setBgColorBody('#ffffff')
		}
	},[themeColor])

	const {
		skeletonAvatarColumns,
		columns, 
		data,
		loading,
		onCheckBoxChange,
		onIndeterminateCheckBoxChange,
		onPaginationChange,
		onSelectChange,
		onSort,
		pageSizes,
		selectable,
		skeletonAvatarProps,
		pagingData,
		autoResetSelectedRows,
		enablePage = true,
		enableSize = true,
		multiHeader = false
	} = props

	const { pageSize, pageIndex, total } = pagingData

	const pageSizeOption = useMemo(() => pageSizes.map(
		number => ({value: number, label: `${number} / page`})
	), [pageSizes])

	const handleCheckBoxChange = (checked, row) => {
		if(!loading) {
			onCheckBoxChange?.(checked, row)
		}
	}

	const handleIndeterminateCheckBoxChange = (checked, rows) => {
		if(!loading) {
			onIndeterminateCheckBoxChange?.(checked, rows)
		}
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
	} = useTable(
		{
			columns,
			data,
			manualPagination: true,
			manualSortBy: true,
			autoResetSelectedRows
		},
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
			if(selectable) {
				hooks.visibleColumns.push(columns => [
					{
						id: 'selection',
						Header: (props) => (
							<div>
								<IndeterminateCheckbox 
									{...props.getToggleAllRowsSelectedProps()} 
									onIndeterminateCheckBoxChange={e => handleIndeterminateCheckBoxChange(e.target.checked, props.rows)}
								/>
							</div>
						),
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox 
									{...row.getToggleRowSelectedProps()}
									onCheckBoxChange={e => handleCheckBoxChange(e.target.checked, row.original)}
								/>
							</div>
						),
						sortable: false,
					},
					...columns,
				])
			}
		}
	)

	const handlePaginationChange = page => {
		if(!loading) {
			onPaginationChange?.(page)
		}
	}

	const handleSelectChange = value => {
		if(!loading) {
			onSelectChange?.(Number(value))
		}
	}

	const handleSort = column => {
		if(!loading) {
			const { id, isSortedDesc, toggleSortBy, clearSortBy } = column
			const sortOrder = isSortedDesc ? 'desc' : 'asc'
			toggleSortBy(!isSortedDesc)
			onSort?.({order: sortOrder, key: id}, {id, clearSortBy})
		}
	}

	const tableProps = getTableProps();
	const { key, ...restTableProps } = tableProps;
	return (
		<Loading loading={loading && data.length !== 0} type="cover">
			<Table  >
				<THead style={{position:"sticky", top:"0", zIndex:"1"}}>
					{headerGroups.map((headerGroup,i) => (
						( multiHeader ? 

							<React.Fragment key={i}>
								<Tr {...headerGroup.getHeaderGroupProps()} >
									{headerGroup.headers.map((column, index) => (
										column.hideRow?
										null
										:
										(
											index === headerGroup.headers.length - 1 && column.fixedColumn?
											<Th key={index} {...column.getHeaderProps()} rowSpan={column.multiData ? "1" : "2"} colSpan={column.multiData ? column.dataSplit.length : "1"}  style={{ position: 'sticky', right: 0, backgroundColor: bgColorHead, zIndex: 1}}>
												{column.render('Header') && (
													column.sortable ? (
														<div className="cursor-pointer" onClick={() => handleSort(column)}>
															{column.render('Header')}
															<span>
																<Sorter sort={column.isSortedDesc}/>
															</span>
														</div>
													)
													:
													(
														column.width && column.customWidth ? (
															<div style={{ width: column.width }}>
																{column.render('Header')}
															</div>
														) :
														column.render('Header')
													)
												)}
											</Th>
											:
											// TAMBAH NOWRAP SUPAYA GA BREAK/WRAP
											<Th {...column.getHeaderProps()} rowSpan={column.multiData ? "1" : "2"} colSpan={column.multiData ? column.dataSplit.length : "1"} className={ column.multiData ? "whitespace-nowrap text-center-important" : "whitespace-nowrap"}>
												{column.render('Header') && (
													column.sortable ? (
														<div className="cursor-pointer" onClick={() => handleSort(column)}>
															{column.render('Header')}
															<span>
																<Sorter sort={column.isSortedDesc}/>
															</span>
														</div>
													)
													:
													(
														column.width && column.customWidth ? (
															<div style={{ width: column.width }}>
																{column.render('Header')}
															</div>
														) :
														column.render('Header')
													)
												)}
											</Th>
										)
										
									))}
								</Tr>

								<Tr>
									{headerGroup.headers.map((column, index) => (
										column.multiData ?

										column.dataSplit.map((d, i) => (
												<Th key={i} className="text-center-important whitespace-nowrap">
													<div>{d}</div>
												</Th>
										))
										
										:
										null
									))}
								</Tr>
							</React.Fragment>
							: 
							<Tr {...headerGroup.getHeaderGroupProps()} >
								{headerGroup.headers.map((column, index) => (
									column.hideRow?
									null
									:
									(
										index === headerGroup.headers.length - 1 && column.fixedColumn?
										<Th {...column.getHeaderProps()} style={{ position: 'sticky', right: 0, backgroundColor: bgColorHead, zIndex: 1}}>
											{column.render('Header') && (
												column.sortable ? (
													<div className="cursor-pointer" onClick={() => handleSort(column)}>
														{column.render('Header')}
														<span>
															<Sorter sort={column.isSortedDesc}/>
														</span>
													</div>
												)
												:
												(
													column.width && column.customWidth ? (
														<div style={{ width: column.width }}>
															{column.render('Header')}
														</div>
													) :
													column.render('Header')
												)
											)}
										</Th>
										:
										// TAMBAH NOWRAP SUPAYA GA BREAK/WRAP
										<Th {...column.getHeaderProps()} className="whitespace-nowrap">
											{column.render('Header') && (
												column.sortable ? (
													<div className="cursor-pointer" onClick={() => handleSort(column)}>
														{column.render('Header')}
														<span>
															<Sorter sort={column.isSortedDesc}/>
														</span>
													</div>
												)
												:
												(
													column.width && column.customWidth ? (
														<div style={{ width: column.width }}>
															{column.render('Header')}
														</div>
													) :
														column.render('Header')
													// <div style={{ width: 100 }}>
													// 		{column.render('Header')}
													// </div>
													
												)
											)}
										</Th>
									)
									
								))}
							</Tr>
						)
					))}
				</THead>
				{
					loading && data.length === 0  ? 
					(
						<TableRowSkeleton 
							columns={columns.length}
							rows={pagingData.pageSize}
							avatarInColumns={skeletonAvatarColumns}
							avatarProps={skeletonAvatarProps}
						/>
					)
					:
					(
						<TBody {...getTableBodyProps()}>
						{page.length > 0 ? (
							page.map((row, i) => {
							prepareRow(row);
							return (
								<Tr key={i} {...row.getRowProps()}>
								{row.cells.map((cell, index) => {
									if (cell.column.hideRow) {
										return null;
									}

									if (cell.column.multiData){
										return (
											<>
												{cell.value.map((e, i) => (
													<Td key={`${row.id}-${cell.id}-${i}`} className="whitespace-nowrap text-center-important">
														{e}
													</Td>
													// <div>asdsa</div>
												))}
											</>
											// {cell.column.dataSplit.map((e, i) => {

											// })})
										)
										// )
										// cell.column.dataSplit.map((_e, i) => {
										// 	return (
										// 		<Td className="whitespace-nowrap">
										// 			{/* <div></div> */}
										// 			{/* {cell.render('Cell')} */}
										// 		</Td>
										// 	);
										// })
										// return (
										// 	<Td className="whitespace-nowrap">
										// 		{cell.column.dataSplit.map((e, i) => {
										// 			return (<div>{e}</div>)
										// 		})}
										// 		{/* {cell.render('Cell')} */}
										// 	</Td>
										// );
									} else {
										if (index === row.cells.length - 1 && cell.column.fixedColumn) {
											return (
												<Td key={index} {...cell.getCellProps()} style={{ position: 'sticky', right: 0, backgroundColor: bgColorBody }}>
												{cell.render('Cell')}
												</Td>
											);
											} else {
											return (
												<Td key={index} {...cell.getCellProps({ style: { width: cell.column.width } })} className="whitespace-nowrap">
												{cell.render('Cell')}
												</Td>
											);
										}
									}
									
								})}
								</Tr>
							);
							})
						) : (
							// Render "No data available" message if there's no data
							<Tr><Td colSpan="100%">No data available</Td></Tr>
						)}
						</TBody>
					)
				}
			</Table>
			<div className="flex items-center justify-between mt-4">
				{enablePage && (
					<Pagination
					pageSize={pageSize}
					currentPage={pageIndex}
					total={total}
					onChange={handlePaginationChange}
					/>
				)}

				{enableSize && (
					<div style={{ minWidth: 130 }}>
					<Select
						size="sm"
						menuPlacement="top"
						isSearchable={false}
						value={pageSizeOption.filter(option => option.value === pageSize)}
						options={pageSizeOption}
						onChange={option => handleSelectChange(option.value)}
					/>
					</div>
				)}
			</div>
		</Loading>
	)
}

DataTable.propTypes = {
	columns: PropTypes.array,
	data: PropTypes.array,
	loading: PropTypes.bool,
	onCheckBoxChange: PropTypes.func,
	onIndeterminateCheckBoxChange: PropTypes.func,
	onPaginationChange: PropTypes.func,
	onSelectChange: PropTypes.func,
	onSort: PropTypes.func,
	pageSizes: PropTypes.arrayOf(PropTypes.number),
	selectable: PropTypes.bool,
	skeletonAvatarColumns: PropTypes.arrayOf(PropTypes.number),
	skeletonAvatarProps: PropTypes.object,
	pagingData: PropTypes.shape({
		total: PropTypes.number,
		pageIndex: PropTypes.number,
		pageSize: PropTypes.number,
	}),
	autoResetSelectedRows: PropTypes.bool
}

DataTable.defaultProps = {
	pageSizes: [10, 25, 50, 100],
	pagingData: {
		total: 0,
		pageIndex: 1,
		pageSize: 10,
	},
	data: [],
	columns: [],
	selectable: false,
	loading: false,
	autoResetSelectedRows: true,
}


export default DataTable