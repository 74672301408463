import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar        : '',
    userName      : '',
    email         : '',
    authority     : [],
    canAccess     : [],
    mode          : 'light',
    scannerDialog : false,
    scanValue     : '',
    goldRate      : '',
    usdRate       : '',
    showFilter    : false,
    optionFilter  : {},
    consignmentItem : [],
    store : 1
}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
        setModeUser: (state, action) => {
			state.mode = action.payload
		},
        setScannerDialog: (state, action) => {
            state.scannerDialog = action.payload
        },
        setScanValue: (state, action) => {
            state.scanValue = action.payload
        },
        setHeaderExchangeRate: (state, action) => {
            state.goldRate = action.payload.goldRate
            state.usdRate = action.payload.usdRate
        },
        toggleFilterDialog: (state, action) => {
            state.showFilter = action.payload
        },
        setOptionFilter: (state, action) => {
            state.optionFilter = action.payload
        },
        setConsignmentItem: (state, action) => {
            state.consignmentItem = action.payload
        }
	},
})

export const { setUser, setModeUser, setScannerDialog, setScanValue, setHeaderExchangeRate, toggleFilterDialog, setOptionFilter, setConsignmentItem } = userSlice.actions

export default userSlice.reducer