import { Dialog } from 'components/ui'
import { setScannerDialog, setScanValue } from 'store/auth/userSlice'
import { useSelector, useDispatch } from 'react-redux'
import CheckPriceScanner from 'views/Merchandising/CheckPrice/components/CheckPriceScanner'

const Scanner = () => {
	const dispatch = useDispatch()
	const { scannerDialog } = useSelector((state) => state.auth.user)

	const onDialogClose = () => {
		dispatch(setScannerDialog(false))
	}

	const onScan = (scanResult) => {
		dispatch(setScannerDialog(false))
		dispatch(setScanValue(scanResult))
	}

	return (
		<Dialog
			isOpen={scannerDialog}
			onClose={onDialogClose}
			onRequestClose={onDialogClose}
			className="max-w-xl"
			width="100%"
			style={{ content: { marginTop:100 } }}
			>
			<h4 className="mb-8">Scan QR</h4>
			<CheckPriceScanner onScan={onScan}/>
		</Dialog>
	)
		
}

export default Scanner